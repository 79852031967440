import axios from "axios";

export default (token = false) => {
  return axios.create({
    baseURL: "https://api.msb.hidayahsmart.solutions/v1/",
    // baseURL: 'http://192.168.60.86:8000/v1/',https://api.msb.hidayahsmart.solutions/
    headers: {
      "content-type": "application/json",

      authorization: `Bearer ${token}`,
    },
  });
};
